import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "icon-spinner animate-spin",
  style: {"font-size":"1rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
    class: _normalizeClass(["px-4 py-3 rounded-full text-solid flex items-center justify-center bg-primary-100", {
      'bg-positive-10 text-positive-40': _ctx.disabled,
    }]),
    disabled: _ctx.disabled
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ], 64))
  ], 10, _hoisted_1))
}