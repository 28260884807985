/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import vm from "../main";
import type { TranslateResult } from "vue-i18n";

interface I18nTextObj {
  [key: string]: TranslateResult;
}

const formatTime = (time: number, toMonth = false) => {
  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const now = new Date().getTime();
  const diffValue = Math.abs(now - time);

  const minC = diffValue / minute;
  const hourC = diffValue / hour;
  const dayC = diffValue / day;
  const monC = diffValue / month;
  const result = {
    time: 0,
    type: "d",
    isAgo: true,
  };
  if (now - time < 0) {
    result.isAgo = false;
  }
  if (toMonth) {
    if (monC >= 1) {
      result.time = Math.floor(monC);
      result.type = "M";
      return result;
    }
  }
  if (dayC >= 1) {
    result.time = Math.floor(dayC);
    result.type = "d";
  } else if (hourC >= 1 && hourC < 24) {
    result.time = Math.floor(hourC);
    result.type = "h";
  } else if (minC >= 1 && minC < 60) {
    result.time = Math.floor(minC);
    result.type = "m";
  } else if (diffValue >= 0 && diffValue <= minute) {
    result.time = Math.floor(diffValue / 1000);
    result.type = "s";
  }

  return result;
};

export const dateFromNow = (time: number): string => {
  const tc = vm.$tc.bind(vm);
  const n = formatTime(time, true).time;
  const type = formatTime(time, true).type;
  const i = n > 1 ? 1 : 0;
  const langKeyObj: I18nTextObj = {
    M: tc("utils.timeUtils.monthAgo", i, { n }),
    d: tc("utils.timeUtils.dayAgo", i, { n }),
    h: tc("utils.timeUtils.hourAgo", i, { n }),
    m: tc("utils.timeUtils.minuteAgo", i, { n }),
    s: tc("utils.timeUtils.secondAgo", i, { n }),
  };
  return langKeyObj[type];
};
