
import { defineComponent } from "vue";

export default defineComponent({
  props: ["title", "disabled", "loading"],
  emits: ["click"],
  setup(props, context) {
    const click = () => {
      if (props.disabled || props.loading) return;
      context.emit("click");
    };

    return {
      click,
    };
  },
});
