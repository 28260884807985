
import { defineComponent } from "vue";
import Loading from "@/components/common/Loading.vue";

export default defineComponent({
  name: "LoadingMask",
  components: { Loading },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
