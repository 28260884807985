
import { defineComponent, ref, Ref, watch } from "vue";

export default defineComponent({
  name: "ItemGroup",
  props: {
    maxInRow: {
      type: [String, Number],
      default: 4,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    rowClass: {
      type: String,
    },
    itemClass: {
      type: String,
    },
    gap: {
      type: String,
    },
  },
  setup(props) {
    const expand = ref(false);
    const fullData: Ref<Array<Array<unknown>>> = ref([]);
    const displayData: Ref<Array<Array<unknown>>> = ref([]);
    const formatData = (dataSource: Array<unknown>) => {
      const groupList: Array<Array<unknown>> = [];
      let arr: unknown[];
      let startIndex = 0;
      let endIndex = startIndex + Number(props.maxInRow);
      if (dataSource.length > props.maxInRow) {
        while (startIndex < dataSource.length) {
          arr = dataSource.slice(startIndex, endIndex);
          groupList.push(arr);
          startIndex = endIndex;
          endIndex = startIndex + Number(props.maxInRow);
        }
      } else {
        groupList.push(dataSource);
      }
      return groupList;
    };

    const spandFn = () => {
      expand.value = true;
      displayData.value = fullData.value;
    };

    watch(
      () => props.dataSource,
      (newData) => {
        fullData.value = formatData(newData);
        displayData.value = expand.value
          ? fullData.value
          : fullData.value.slice(0, 1);
      },
      {
        immediate: true,
      }
    );

    return {
      fullData,
      displayData,
      expand,
      spandFn,
    };
  },
});
