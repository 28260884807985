import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayData, (row, index) => {
      return (_openBlock(), _createElementBlock("ul", {
        class: _normalizeClass(["flex", _ctx.rowClass]),
        key: index,
        style: _normalizeStyle(
        _ctx.gap && index < _ctx.displayData.length - 1 ? `margin-bottom: ${_ctx.gap}` : ''
      )
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (item, i) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["relative rounded-lg overflow-hidden", _ctx.itemClass]),
            key: i,
            style: _normalizeStyle(_ctx.gap && i < row.length - 1 ? `margin-right: ${_ctx.gap};` : '')
          }, [
            (
            _ctx.dataSource.length > Number(_ctx.maxInRow) &&
            !_ctx.expand &&
            i === row.length - 1
          )
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.spandFn && _ctx.spandFn(...args)), ["stop"])),
                  class: "item-mask"
                }, " +" + _toDisplayString(_ctx.dataSource.length - Number(_ctx.maxInRow) + 1), 1))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", { item: item }, undefined, true)
          ], 6))
        }), 128))
      ], 6))
    }), 128))
  ]))
}