
import { computed, defineComponent, PropType, ref, watch } from "vue";
import LazyImage from "@/components/common/LazyImage.vue";

export interface ImageItem {
  src: string;
  [key: string]: unknown;
}

export default defineComponent({
  name: "ImageGroup",
  components: { LazyImage },
  emits: ["click-thumbnail"],
  props: {
    dataSource: {
      type: Array as PropType<ImageItem[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    const thumbnails = ref<ImageItem[]>([]);
    const curImgUrl = ref("");
    const curIndex = ref(0);
    const startIndex = ref(0);
    const endIndex = computed(() => startIndex.value + 5);

    const formatData = (dataSource: ImageItem[]) => {
      const list = dataSource.map((item, index) => {
        return { index, ...item };
      });
      return list.slice(startIndex.value, endIndex.value);
    };

    const clickHandler = (data: ImageItem) => {
      curImgUrl.value = data.src;
      curIndex.value = data.index as number;
      context.emit("click-thumbnail", data);
    };

    const clickPrevBtn = () => {
      if (startIndex.value <= 0) return;
      startIndex.value = startIndex.value - 1;
    };

    const clickNextBtn = () => {
      if (endIndex.value >= props.dataSource.length) return;
      startIndex.value = startIndex.value + 1;
    };

    watch(
      () => props.dataSource,
      (value: ImageItem[]) => {
        thumbnails.value = formatData(value);
        clickHandler(thumbnails.value[0]);
      },
      { immediate: true }
    );

    watch(startIndex, () => {
      thumbnails.value = formatData(props.dataSource);
    });

    return {
      thumbnails,
      curImgUrl,
      startIndex,
      endIndex,
      curIndex,
      clickHandler,
      clickPrevBtn,
      clickNextBtn,
    };
  },
});
