
import { defineComponent, onBeforeMount, ref, Ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import LazyImage from "@/components/common/LazyImage.vue";
import Button from "@/components/common/Button.vue";
import { itemDetailReq } from "@/apis";
import { LatestTxn, CollectionItem, ItemDetail } from "@/apis/itemDetailReq";
import OTable, { Record } from "@/components/common/Table.vue";
import ItemGroup from "@/components/ItemGroup.vue";
import { dateFromNow } from "@/utils/timeUtils";
import { decimalizeNumber, amountFormat } from "@/utils/numberUtils";
import { textAbbreviation } from "@/utils/textAbbreviationUtils";
import { setImgSize } from "@/utils/setImgSizeUtil";
import LoadingMask from "@/components/common/LoadingMask.vue";
import Clipboard from "clipboard";
import ImageGroup, { ImageItem } from "@/components/ImageGroup.vue";
import { useI18n } from "vue-i18n";
import { compressToBase64, decompressFromBase64 } from "lz-string";

export interface QueryDataItem {
  contract: string;
  tokenId: string;
}

export default defineComponent({
  name: "ItemDetail",
  components: { LazyImage, Button, OTable, ItemGroup, LoadingMask, ImageGroup },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    let queryData = (route.query.data || "").toString();
    if (queryData) {
      queryData = queryData.replace(/\s/g, "+");
    }
    const decodeData = decompressFromBase64(queryData);
    const columns = ref();
    const loading = ref(false);

    const details: Ref<ItemDetail> = ref<ItemDetail>({} as ItemDetail);
    const itemActivityRef = ref<LatestTxn[]>([]);
    const collectionListRef = ref<CollectionItem[]>([]);
    const showPopMessage = ref(false);
    const contractRef = ref("");

    const formatImageGroupData = (dataString: string) => {
      const arr = dataString.split("@");
      const contract = arr[0];
      const tokenIdList = arr[1].split("-");
      return tokenIdList.map((tokenId: string) => {
        const src = `${process.env.VUE_APP_IMG_DOMAIN}/res/eth/${contract}/${tokenId}?size=692x692&format=webp&needOrigin=1`;
        return {
          src,
          contract,
          tokenId,
        };
      });
    };

    const imageGroup = ref<ImageItem[]>([]);

    const getItemDetail = async (contractAddr: string, tokenId: string) => {
      const params = {
        contractAddr,
        tokenId,
        language: navigator.language,
        country: "",
        moreInCollection: 5,
      };

      loading.value = true;
      const [err, res] = await itemDetailReq(params);
      loading.value = false;

      if (err) {
        console.error(err);
      }
      if (res) {
        if (res.code === 0 || res.code === 4001) {
          const {
            latestTxns,
            belongToCollection: { items },
            contract,
          } = res.data;
          itemActivityRef.value = latestTxns;
          collectionListRef.value = items;
          contractRef.value = contract;
          details.value = res.data;
        }
      }
    };

    const toOpensea = () => {
      window.open(details.value.openseaLink, "_blank");
    };

    const toCollectionDetailPage = () => {
      let routeData = router.resolve({
        name: "CollectionDetail",
        params: { contract: contractRef.value },
      });
      window.open(routeData.href, "_self");
    };

    const copyContractFn = () => {
      let clipboard = new Clipboard(".copy-btn");
      clipboard.on("success", () => {
        showPopMessage.value = true;
        setTimeout(() => {
          showPopMessage.value = false;
        }, 2000);
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        clipboard.destroy();
      });
    };

    const itemName = computed(() => {
      let itemName = details.value.name;
      const belongToCollectionName = details.value.belongToCollection?.name;
      const tokenId = details.value.tokenId;
      if (!itemName) {
        if (belongToCollectionName) {
          itemName = belongToCollectionName + tokenId;
        } else {
          itemName = "---";
        }
      }
      return itemName;
    });

    const floorPriceChangeRate = computed(() => {
      let rate = 0;
      const floorPrice = details.value.belongToCollection?.floorPrice;
      const floor24h = details.value.belongToCollection?.floor24h;
      if (floorPrice && floor24h) {
        rate = ((floorPrice - floor24h) / floor24h) * 100;
      }
      return rate;
    });

    const marketPriceChangeRate = computed(() => {
      let rate = 0;
      const market = details.value.belongToCollection?.market;
      const marketPre = details.value.belongToCollection?.marketPre;
      if (market && marketPre) {
        rate = ((market - marketPre) / marketPre) * 100;
      }
      return rate;
    });

    const volum24ChangeRate = computed(() => {
      let rate = 0;
      const volume24h = details.value.belongToCollection?.volume24h;
      const volume24hPre = details.value.belongToCollection?.volume24hPre;
      if (volume24h && volume24hPre) {
        rate = ((volume24h - volume24hPre) / volume24hPre) * 100;
      }
      return rate;
    });

    const handleClickThumbnail = (res: ImageItem) => {
      const { contract, tokenId } = res;
      getItemDetail(contract as string, tokenId as string);
    };

    const onClickCollectionItem = (contract: string, tokenId: string) => {
      const dataStr = `${contract}@${tokenId}`;
      const encodeData = compressToBase64(dataStr);
      let routeData = router.resolve({
        name: "itemDetail",
        query: { data: encodeData },
      });
      window.open(routeData.href, "_self");
    };

    const collectionName = (name: string, tokenId: string) => {
      if (name) return name;
      return "#" + tokenId;
    };

    onBeforeMount(() => {
      columns.value = [
        {
          key: "transferType",
          title: t("general.event"),
          render: (h: any, record: Record) => {
            const { transferType } = record;
            return h(
              "p",
              {
                class: "text-sm font-bold",
              },
              transferType
            );
          },
        },
        {
          key: "price",
          title: t("general.price"),
          uppercase: true,
          align: "right",
          render: (h: any, record: Record) => {
            const { price } = record;
            return h(
              "p",
              {
                class: "text-sm",
              },
              [
                h("i", {
                  class: "icon-eth mr-2 text-primary-100",
                  style: "font-size: 0.6875rem",
                }),
                decimalizeNumber(Number(price), false, "ETH"),
              ]
            );
          },
        },
        {
          key: "transferTime",
          title: t("general.dates"),
          uppercase: true,
          align: "right",
          render: (h: any, record: Record) => {
            const { transferTime } = record;
            return h(
              "p",
              {
                class: "text-sm font-bold",
              },
              dateFromNow(Number(transferTime) * 1000)
            );
          },
        },
        {
          key: "nftFrom",
          title: t("general.from"),
          uppercase: true,
          align: "right",
          render: (h: any, record: Record) => {
            const { nftFrom = "", nftFromLink } = record;
            return h(
              "p",
              {
                class: "text-sm font-bold text-primary-100",
              },
              h(
                "a",
                {
                  href: nftFromLink,
                  target: "_blank",
                },
                textAbbreviation(nftFrom, 8, 3)
              )
            );
          },
        },
        {
          key: "nftTo",
          title: t("general.to"),
          uppercase: true,
          align: "right",
          render: (h: any, record: Record) => {
            const { nftTo = "", nftToLink } = record;
            return h(
              "p",
              {
                class: "text-sm font-bold text-primary-100",
              },
              h(
                "a",
                {
                  href: nftToLink,
                  target: "_blank",
                },
                textAbbreviation(nftTo, 8, 3)
              )
            );
          },
        },
      ];
      imageGroup.value = formatImageGroupData(decodeData as string);
    });

    return {
      loading,
      columns,
      itemActivityRef,
      collectionListRef,
      details,
      itemName,
      showPopMessage,
      floorPriceChangeRate,
      marketPriceChangeRate,
      volum24ChangeRate,
      imageGroup,
      collectionName,
      copyContractFn,
      decimalizeNumber,
      setImgSize,
      toOpensea,
      handleClickThumbnail,
      amountFormat,
      toCollectionDetailPage,
      getItemDetail,
      onClickCollectionItem,
      t,
    };
  },
});
