/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

export const textAbbreviation = (
  addr: string | number,
  before = 6,
  after = 4
): string => {
  if (typeof addr === "number") addr = addr.toString();
  if (!addr || addr.length < before + after) {
    return addr;
  }
  return (
    addr.substring(0, before) +
    "..." +
    addr.substring(addr.length - after, addr.length)
  );
};
